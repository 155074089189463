<template>
    <div class="min-h-screen flex relative lg:static surface-ground" v-if="applicationReadIndex === 0">
        <div class="max-h-screen overflow-y-scroll w-full">
            <div
                class="flex align-items-center px-5 surface-0 border-bottom-1 surface-border"
            >
                <div style="max-width: 1024px; margin: 0 auto; width: 100%;"
                     class="flex align-items-center justify-content-between">
                    <router-link
                        to="/surveys"
                        class="py-3 flex align-items-center cursor-pointer no-underline"
                    >
                        <img src="./../assets/img/logo.jpg" class="h-3rem mt-1 mr-4 inline-block" alt=""/>
                    </router-link>

                    <div>
                        <ul class="flex m-0 list-none p-0 align-items-center">
                            <li v-for="menuItem in userMenuItems">
                                <router-link
                                    :to="menuItem.path"
                                    class="no-underline inline-block p-3 py-2 uppercase surface-0 hover:surface-50"
                                >
                                    {{ menuItem.label }}
                                </router-link>
                            </li>
                            <li>
                                <Dropdown
                                    class="border-0 ml-2 uppercase text-gray-500"
                                    :value="locale"
                                    :options="[{id: 'de', label: $t('German')}, {id: 'en', label: $t('English')}]"
                                    option-value="id"
                                    option-label="label"
                                    @change="changeLanguageTmp"
                                />
                            </li>
                            <li>
                                <router-link
                                    to="/logout"
                                    class="no-underline inline-block p-3 py-2 uppercase surface-0 hover:surface-50"
                                >
                                    Logout
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div
                style="height: .25rem;"
                v-show="$store.getters.getLoadingIndex === 0"
            />
            <ProgressBar
                v-show="$store.getters.getLoadingIndex > 0"
                mode="indeterminate"
                style="height: .25rem;"
            />

            <div class="p-5 flex flex-column flex-auto">
                <router-view
                    style="max-width: 1024px; margin: 0 auto; width: 100%;">
                </router-view>
            </div>
        </div>

        <ConfirmDialog :acceptLabel="$t('Yes')" :rejectLabel="$t('Abort')"></ConfirmDialog>
    </div>
</template>

<script>
    import ConfirmDialog from 'primevue/confirmdialog';
    import ProgressBar from 'primevue/progressbar';
    import Dropdown from "primevue/dropdown";

    export default {
        components: {
            ConfirmDialog,
            ProgressBar,
            Dropdown
        },
        mounted() {
            this.locale = this.$i18n.locale;
            this.$getFromApi('account', 'me', (entity) => {
                this.$store.commit('setCurrentUser', entity);
                this.$i18n.locale = entity.locale;
                this.applicationReadIndex--;
            });
            this.$http.get('settings/current').then(response => {
                this.$store.commit('setSettings', response.data);
                this.applicationReadIndex--;
            });
        },
        methods: {
            changeLanguageTmp(event) {
                if(this.$route.path.indexOf('surveys/') >= 0) {
                    this.$confirm.require({
                        message: this.$t('When changing the language, the unsaved data is lost. Really continue?'),
                        header: this.$t('Warning'),
                        acceptLabel: this.$t('Yes'),
                        rejectLabel: this.$t('Abort'),
                        accept: () => {
                            this.locale = event.value;
                            this.changeLanguage(event.value);
                        },
                        reject: () => {
                        }
                    });
                } else {
                    this.locale = event.value;
                    this.changeLanguage(event.value);
                }
            },
            changeLanguage(newLanguage) {
                this.$i18n.locale = newLanguage;
                this.applicationReadIndex++;
                this.$http.get(
                    'settings/current',
                    {
                        headers: {
                            'Accept-Language': newLanguage
                        }
                    },
                ).then(response => {
                    this.$store.commit('setSettings', response.data);
                    this.applicationReadIndex--;
                });

                this.$i18n.locale = newLanguage;
            },
        },
        computed: {
            routeMeta() {
                return this.$route.meta ?? {};
            },
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            userMenuItems() {
                return this.menuItems.filter((item) => {
                    return !('userCanSee' in item) || item.userCanSee(this.$store.getters.currentUser)
                });
            },
            menuItems() {
                let menuItems = [
                    {
                        label: this.$t('Surveys'),
                        path: '/surveys'
                    },
                    {
                        label: this.$t('Companies'),
                        path: '/companies',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('admin') >= 0
                                && user.permissions['admin'] === true;
                        }
                    },
                    {
                        icon: 'fi fi-rr-user',
                        label: this.$t('Users'),
                        path: '/users',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('admin') >= 0
                                && user.permissions['admin'] === true;
                        }
                    },
                    {
                        icon: 'fi fi-rr-lock',
                        label: this.$t('API & integrations'),
                        path: '/api-keys',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('admin') >= 0
                                && user.permissions['admin'] === true;
                        }
                    },
                    /*{
                        icon: 'fi fi-rr-user',
                        label: 'Logout',
                        path: '/logout',
                    },
                    {
                        icon: 'fi fi-rr-database',
                        label: 'Version & Status',
                        path: '/version-and-status'
                    },
                    {
                        icon: 'fi fi-rr-fingerprint',
                        label: 'Token',
                        path: '/token'
                    },*/
                ];

                return menuItems;
            }
        },
        data() {
            return {
                locale: false,
                applicationReadIndex: 2,
            }
        }
    }
</script>
